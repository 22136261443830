import { FC, useCallback, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { IFlight } from '../../../type';
import { formatToINR, getBurnCoin, getDiscountPrice, getEarnCoin } from '../../../utils';
import { getUserInputState } from '../../../store/slices/userInputSlice';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../store/slices/userInfoSlice';
import { maxBy, minBy } from 'lodash';
import { getFeatureFlag } from '../../../store/slices/featuresSlice';
import OACoinBannerSecondary from '../../../components/OACoinBannerSecondary';
import { OAImage } from '../../../components';
import ConvenienceBanner from '../../../components/OAConvenienceBanner';

interface FlightDetailProps {
  data: IFlight;
  tripType: 'R' | 'O';
  isInternational?: boolean;
  airportReviewData: any;
  nearbyAirports: any;
}

const FlightDetail: FC<FlightDetailProps> = ({
  data,
  tripType,
  isInternational,
  airportReviewData,
  nearbyAirports
}) => {

  const { isCoinsApplied, travelDetail } = useSelector(getUserInputState);
  const { coins } = useSelector(getUserInfo);
  const { burnCoinsFeat } = useSelector(getFeatureFlag);
  const tc = travelDetail?.travellerCount;
  const totalTraveller = (tc?.adult ?? 0) + (tc?.child ?? 0) + (tc?.infant ?? 0);

  const onwardSegments = data?.segments.filter((segment) => segment.departureType === 'ONWARD');
  const returnSegments = data?.segments.filter((segment) => segment.departureType === 'RETURN');

  const minOnwardObj = minBy(onwardSegments, 'segmentId');
  const maxOnwardObj = maxBy(onwardSegments, 'segmentId');
  const onwardDepartureTime = minOnwardObj?.departure?.time;
  const onwardDeparturePlace = minOnwardObj?.departure?.airport?.iata;
  const onwardArrivalTime = maxOnwardObj?.arrival?.time;
  const onwardArrivalPlace = maxOnwardObj?.arrival?.airport?.iata;

  const minReturnObj = minBy(returnSegments, 'segmentId');
  const maxReturnObj = maxBy(returnSegments, 'segmentId');
  const returnDepartureTime = minReturnObj?.departure?.time;
  const returnDeparturePlace = minReturnObj?.departure?.airport?.iata;
  const returnArrivalTime = maxReturnObj?.arrival?.time;
  const returnArrivalPlace = maxReturnObj?.arrival?.airport?.iata;

  const minObj = minBy(data?.segments, 'segmentId');
  const maxObj = maxBy(data?.segments, 'segmentId');
  const departureTime = minObj?.departure?.time;
  const departurePlace = minObj?.departure?.airport?.iata;
  const arrivalTime = maxObj?.arrival?.time;
  const arrivalPlace = maxObj?.arrival?.airport?.iata;
  const discountPrice = getDiscountPrice(data?.price?.value, coins);
  const internationalReturn = tripType === 'R' && isInternational;
  const domesticReturn = tripType === 'R' && !isInternational;

  const savedMoney = data?.price?.value - discountPrice;
  const earnCoin = getEarnCoin(data?.price?.value ?? 0);
  const coinsToBeBurned = getBurnCoin(data?.price?.value, coins);
  const isDiscountedPrice = discountPrice < data?.price?.value;
  const perPaxSave = savedMoney / totalTraveller;

  const earnCoinLabel = (
    <Box display="flex" alignItems="center">
      {domesticReturn ? (
        <>
          <Typography variant="caption">Earning</Typography>
          <Typography mx="4px" fontWeight={600} variant="caption">
            {earnCoin}
          </Typography>
          <Typography variant="caption">coins</Typography>
        </>
      ) : (
        <>
          <Typography variant="caption">Earn</Typography>
          <Typography mx="4px" fontWeight={600} variant="caption">
            {earnCoin}
          </Typography>
          <Typography variant="caption">Niyo coins on this trip</Typography>
        </>
      )}
    </Box>
  );

  const renderAirportDistance = useCallback((iataCode: string, searchedIata: string, isOrigin: boolean) => {
    if (iataCode === searchedIata) return null;

    const airports = isOrigin ? nearbyAirports?.origin?.airports : nearbyAirports?.destination?.airports;
    const airport = airports?.find((a: any) => a.iata === iataCode);

    if (airport) {
      return (
        <Typography variant="body4" color="#8C9097"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}>
          {airport.distance.label} from {searchedIata}
        </Typography>
      );
    }
    return null;
  }, [nearbyAirports]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={domesticReturn ? '10px' : '15px'}
        mb={domesticReturn ? '10px' : '15px'}
      >
        <Box
          display="flex"
          alignItems={isInternational ? 'center' : 'start'}
          flexDirection="column"
          width={'100%'}
          marginRight={domesticReturn ? '0' : '28px'}
        >
          <Box display="flex" width="100%">
            <Stack
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{ m: domesticReturn ? '0 4px 0 0' : '0 10px 0 0' }}
            >
              <Typography variant={tripType === 'O' || isInternational ? 'body1' : 'body2'}>
                {isInternational ? onwardDepartureTime : departureTime}
              </Typography>
              <Typography variant="body4" borderRadius='2px' px='2px' bgcolor={airportReviewData?.isDifferentOnwardDeparture && isInternational ? '#FFEACE' : 'transparent'}>
                {isInternational ? onwardDeparturePlace : departurePlace}
              </Typography>
            </Stack>
            <Stack
              width="100%"
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ m: 0 }}
            >
              <Typography variant="body4" sx={{ color: 'text.secondary' }}>
                {domesticReturn
                  ? data?.returnDurationInfo?.display ?? data?.durationInfo?.display
                  : data?.durationInfo?.display}
              </Typography>
              <OAImage
                src="line-flight-card-lg.svg"
                alt="line"
                sx={{ width: '68px', height: '6px' }}
              />
              <Typography
                variant="body4"
                sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
              >
                {data?.stops?.display}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{ m: domesticReturn ? '0 0 0 4px' : '0 0 0 10px' }}
            >
              <Typography
                display="flex"
                alignItems="center"
                variant={tripType === 'O' || isInternational ? 'body1' : 'body2'}
              >
                {isInternational ? onwardArrivalTime : arrivalTime}{' '}
                {data?.arrivalDayOffset > 0 && (
                  <Typography
                    variant="body3"
                    sx={{ ml: '4px', color: 'error.main', fontWeight: 500 }}
                  >
                    +{data?.arrivalDayOffset}
                  </Typography>
                )}
              </Typography>
              <Typography variant="body4" borderRadius='2px' px='2px' bgcolor={airportReviewData?.isDifferentOnwardArrival && isInternational ? '#FFEACE' : 'transparent'}>
                {isInternational ? onwardArrivalPlace : arrivalPlace}
              </Typography>
            </Stack>
          </Box>
          {airportReviewData && (
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" gap='28px'>
              <Box flex={1}>
                {airportReviewData?.isDifferentOnwardDeparture && renderAirportDistance(airportReviewData?.onwardDeparture, airportReviewData?.searchedFrom, true)}
              </Box>
              <Box flex={1} textAlign="end">
                {airportReviewData?.isDifferentOnwardArrival && renderAirportDistance(airportReviewData?.onwardArrival, airportReviewData?.searchedTo, false)}
              </Box>
            </Box>
          )}
          {internationalReturn && (
            <>
              <Box display="flex" mt="15px" width='100%'>
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  sx={{ m: domesticReturn ? '0 4px 0 0' : '0 10px 0 0' }}
                >
                  <Typography variant={tripType === 'R' && !isInternational ? 'body2' : 'body1'}>
                    {isInternational ? returnDepartureTime : departureTime}
                  </Typography>
                  {internationalReturn && (
                    <Typography variant="body4" borderRadius='2px' px='2px' bgcolor={airportReviewData?.isDifferentReturnDeparture ? '#FFEACE' : 'transparent'}>
                      {isInternational ? returnDeparturePlace : departurePlace}
                    </Typography>
                  )}
                </Stack>
                {internationalReturn && (
                  <Stack width="100%" direction="column" justifyContent="center" alignItems="center" sx={{ m: 0 }}>
                    <Typography variant="body4" sx={{ color: 'text.secondary' }}>
                      {data?.returnDurationInfo?.display}
                    </Typography>
                    <OAImage
                      src="line-flight-card-lg.svg"
                      alt="line"
                      sx={{ width: '68px', height: '6px' }}
                    />
                    <Typography
                      variant="body4"
                      sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
                    >
                      {data?.returnStops?.display ?? data?.stops?.display}
                    </Typography>
                  </Stack>
                )}
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  sx={{ m: domesticReturn ? '0 0 0 4px' : '0 0 0 10px' }}
                >
                  <Typography
                    display="flex"
                    alignItems="center"
                    variant={domesticReturn ? 'body1' : 'body2'}
                  >
                    {isInternational ? returnArrivalTime : arrivalTime}{' '}
                    {data?.arrivalDayOffset > 0 && (
                      <Typography
                        variant="body3"
                        sx={{ ml: '4px', color: 'error.main', fontWeight: 500 }}
                      >
                        +{data?.arrivalDayOffset}
                      </Typography>
                    )}
                  </Typography>
                  {internationalReturn && (
                    <Typography variant="body4" borderRadius='2px' px='2px' bgcolor={airportReviewData?.isDifferentReturnArrival ? '#FFEACE' : 'transparent'}>
                      {isInternational ? returnArrivalPlace : arrivalPlace}
                    </Typography>
                  )}
                </Stack>
              </Box>
              {airportReviewData && (
                <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" gap='28px'>
                  <Box flex={1}>
                    {airportReviewData?.isDifferentReturnDeparture && renderAirportDistance(airportReviewData?.returnDeparture, airportReviewData?.searchedTo, false)}
                  </Box>
                  <Box flex={1} textAlign="end">
                    {airportReviewData?.isDifferentReturnArrival && renderAirportDistance(airportReviewData?.returnArrival, airportReviewData?.searchedFrom, true)}
                  </Box>
                </Box>
              )}
            </>
          )}

        </Box>
        {(isInternational || tripType === 'O') && (
          <Box>
            <Box display="flex" flexDirection={'column'} alignItems="end">
              {isCoinsApplied && isDiscountedPrice ? (
                <>
                  <Typography
                    variant="body4"
                    sx={{ color: 'text.secondary', textDecoration: 'line-through' }}
                  >
                    {data?.perAdultPricing?.display ?? data?.price?.display}
                  </Typography>
                  {/* {data?.perAdultPricing?.display && <Typography variant='body4'>per adult</Typography>} */}
                </>
              ) : (
                <Typography variant="body4" sx={{ textDecoration: 'line-through' }}>
                  {data?.perAdultPricing?.strikeOffPriceDisplay}
                </Typography>
              )}
              <Typography
                variant="body1"
                sx={{
                  color: isCoinsApplied
                    ? discountPrice > 0
                      ? 'secondary.main'
                      : 'success.main'
                    : 'secondary.main',
                  fontWeight: 600,
                }}
              >
                {isCoinsApplied
                  ? discountPrice > 0
                    ? formatToINR((data?.perAdultPricing?.value ?? 0) - perPaxSave)
                    : 'Free'
                  : data?.perAdultPricing?.display ?? data?.price?.display}
              </Typography>
              {data?.perAdultPricing?.display && <Typography variant="body4" textAlign='end'
               sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
              >per adult</Typography>}
            </Box>
            {/* {data?.seat && (
              <Typography
                variant={domesticReturn ? 'body4' : 'body3'}
                sx={{ color: 'error.main', fontWeight: 400 }}
              >
                {data?.seat}
              </Typography>
            )} */}
          </Box>
        )}
      </Box>
      {((isCoinsApplied && coins > 0 && burnCoinsFeat) || earnCoin > 0) && !domesticReturn && (
        <OACoinBannerSecondary
          isEarnCoins={earnCoin > 0}
          isToggleOn={isCoinsApplied}
          title={
            isCoinsApplied
              ? discountPrice > 0
                ? coinsToBeBurned > 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      fontWeight={600}
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <OAImage
                        src="3xNiyo_coin.png"
                        alt="coins"
                        sx={{ mr: '5px', height: '18px', width: '18px' }}
                      />{' '}
                      Saving{' '}
                      {!domesticReturn && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography ml="4px" variant="caption" fontWeight={600}>
                            {formatToINR(savedMoney)}
                          </Typography>
                        </Box>
                      )}
                      <Typography variant="caption" ml="3px">
                        by redeeming
                      </Typography>
                      <Typography ml="4px" variant="caption" fontWeight={600}>
                        {coinsToBeBurned?.toFixed(2)}
                      </Typography>
                      <Typography variant="caption" ml="3px">
                        coins
                      </Typography>
                    </Box>
                  )
                : 'Flying free using'
              : discountPrice > 0
                ? earnCoin > 0
                  ? earnCoinLabel
                  : null
                : coinsToBeBurned > 0 && (
                  <Box display="flex" alignItems="center">
                    <OAImage src="tick.svg" folder="icons" alt="tick" sx={{ mr: '4px' }} />{' '}
                    Redeeming{' '}
                    <Typography ml="4px" variant="caption" fontWeight={600}>
                      {coinsToBeBurned?.toFixed(2)}
                    </Typography>{' '}
                    <OAImage
                      src="3xNiyo_coin.png"
                      alt="coins"
                      sx={{ mx: '5px', height: '18px', width: '18px' }}
                    />{' '}
                    {!domesticReturn && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2">to save</Typography>
                        <Typography ml="4px" variant="caption" fontWeight={600}>
                          {formatToINR(savedMoney)}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )
          }
          color={
            isCoinsApplied ? (coinsToBeBurned > 0 ? 'primary' : '') : earnCoin > 0 ? 'yellow' : ''
          }
          sx={{
            borderTopRightRadius: '0px',
            borderTopLeftRadius: '0px',
            // borderBottomRightRadius: domesticReturn ? '0px' : '10px',
            // borderBottomLeftRadius: domesticReturn ? '0px' : '10px',
            p: domesticReturn ? '5px 10px' : '5px 15px',
          }}
        />
      )}
      {/* {data?.perAdultPricing?.savedConvenienceFee && data?.perAdultPricing?.savedConvenienceFee > 0 && !domesticReturn && 
        <ConvenienceBanner 
          isCoinsApplied={isCoinsApplied} 
          amount={data?.perAdultPricing?.savedConvenienceFee} 
          text='/ person on convenience fees' 
          icon={<OAImage src={isCoinsApplied ? "toggle-on-conv.svg" : "toggle-off-conv.svg"} 
          folder="icons" 
          alt="tick" 
          sx={{ mr: '4px' }} 
        />} 
      />} */}
    </>
  );
};

export default FlightDetail;
