import { Box, Container, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import {
  formatNumberInINR,
  formatToINR,
  getBurnCoin,
  getDiscountPrice,
  getEarnCoin,
  getRsFromCoins,
} from '../utils';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../store/slices/userInfoSlice';
import { getUserInputState } from '../store/slices/userInputSlice';
import { getFeatureFlag } from '../store/slices/featuresSlice';
import OAFlightRoute from './OAFlightRoute';
import { OACoinBanner } from './OACoinBanner';
import { IFlight } from '../type';
import { getTotalBaggageCount, getTotalMealCount, getTotalSeatCount, getTotalSelectedBaggagePrice, getTotalSelectedMealPrice, getTotalSelectedSeatPrice, getTotalSelectedSmbPrice } from '../store/slices/flightOrderSlice';
import { useParams } from 'react-router-dom';
import { getBaggagesCount, getMealsCount, getSeatsCount } from '../pages/MyBooking/BookingStatus/helpers/smbCount';

// Define the interface for the props of FareListItem
interface FareListItemProps {
  primary: any;
  secondary: string;
  isDiscount?: boolean;
  isTotal?: boolean;
  secondary1?: any;
  fw?: string;
}

// Create the FareListItem component with proper types
const FareListItem = ({
  primary,
  secondary,
  isDiscount,
  isTotal,
  secondary1,
  fw,
}: FareListItemProps) => {
  const variant = isTotal ? 'body1' : 'body2';
  const sx = isTotal ? { fontWeight: 600 } : isDiscount ? { color: 'success.main' } : {};
  return (
    <ListItem sx={{ padding: 0, mb: '12px' }}>
      <ListItemText
        sx={{ m: 0 }}
        primary={
          <Typography variant={variant} sx={sx} fontWeight={fw}>
            {primary}
          </Typography>
        }
      />
      <Typography variant={isTotal ? 'body1' : 'body2'} sx={{ ...sx, fontWeight: 500 }}>
        {secondary1 && (
          <Typography
            variant="body2"
            component="span"
            sx={{ mr: '6px', textDecoration: 'line-through', color: 'text.secondary' }}
          >
            {secondary1}
          </Typography>
        )}
        {secondary}
      </Typography>
    </ListItem>
  );
};

export const OAFareSummary = ({
  onClick, 
  data, 
  selectedSmbData
}: { 
  onClick?: () => void; 
  data?: any, 
  selectedSmbData?: any
}) => {
  const params = useParams();
  const { coins } = useSelector(getUserInfo);
  const { burnCoinsFeat } = useSelector(getFeatureFlag);
  const { isCoinsApplied } = useSelector(getUserInputState);
  const totalSeatCount = useSelector(getTotalSeatCount);
  const totalMealCount = useSelector(getTotalMealCount);
  const totalBaggageCount = useSelector(getTotalBaggageCount);

  const totalSeats = data?.selectedJourneyAncillaries?.[0] ? getSeatsCount(data?.selectedJourneyAncillaries?.[0]) : totalSeatCount;
  const totalMeals = data?.selectedJourneyAncillaries?.[0] ? getMealsCount(data?.selectedJourneyAncillaries?.[0]) : totalMealCount;
  const totalBaggage = data?.selectedJourneyAncillaries?.[0] ? getBaggagesCount(data?.selectedJourneyAncillaries?.[0]) : totalBaggageCount;

  const totalSelectedSmbPrice = useSelector(getTotalSelectedSmbPrice);
  const totalAmount = data?.orderAmount?.totalAmount - (data?.orderAmount?.seatCharges || 0) - (data?.orderAmount?.mealCharges || 0) - (data?.orderAmount?.baggageCharges || 0) + (totalSelectedSmbPrice || 0);
  const discountPrice = getDiscountPrice(totalAmount, coins);
  const burnCoin = getBurnCoin(data?.orderAmount?.totalAmount ?? 0, coins);
  const coinsToBeEarned = getEarnCoin(data?.orderAmount?.totalAmount);
  const burnCoinRs =
    data?.orderAmount?.paymentSummary?.amountInCoins ??
    (isCoinsApplied ? getRsFromCoins(burnCoin) : 0);

  const travellerCount = data?.travellerCount;
  const totalTravellers = travellerCount?.adult + travellerCount?.child + travellerCount?.infant;
  const departure = data?.flightSearchInfo?.from?.city;
  const arrival = data?.flightSearchInfo?.to?.city;
  const flightBasedBreakup = data?.orderAmount?.flightBasedBreakUp;
  const amountpayable = data?.orderAmount?.paymentSummary?.amountInCash;
  const seatPrice = useSelector(getTotalSelectedSeatPrice);
  const mealPrice = useSelector(getTotalSelectedMealPrice);
  const baggagePrice = useSelector(getTotalSelectedBaggagePrice);
  const totalSeatCharges = params?.orderId ? data?.orderAmount?.seatCharges : seatPrice;
  const totalMealCharges = params?.orderId ? data?.orderAmount?.mealCharges : mealPrice;
  const totalBaggageCharges = params?.orderId ? data?.orderAmount?.baggageCharges : baggagePrice;
  const flightIdToDepartureType = data?.flights?.reduce((acc: any, flight: IFlight) => {
    acc[flight?.id] = flight?.departureType;
    return acc;
  }, {});

  // sorting the flightBasedBreakup array by onward and return
  const sortedFlightBasedBreakup = flightBasedBreakup && flightBasedBreakup.length > 0 ? [...flightBasedBreakup].sort((a: any, b: any) => {
    const departureTypeA = flightIdToDepartureType?.[a?.flightId];
    const departureTypeB = flightIdToDepartureType?.[b?.flightId];

    if (departureTypeA === 'ONWARD' && departureTypeB === 'RETURN') {
      return -1;
    } else if (departureTypeA === 'RETURN' && departureTypeB === 'ONWARD') {
      return 1;
    } else {
      return 0;
    }
  }) : [];

  //when flightBasedBreakup is empty, using this
  const taxAndFees = (data?.orderAmount?.totalTaxAndFee ?? 0);
  const convenienceFeeTitle = (
    <Box display="flex" flexDirection="column">
      <Box>
      <Typography variant='body2' component="span">Convenience fee (incl. GST)</Typography>
      <Typography variant='body2' component="sup" sx={{ fontSize: '0.7em', verticalAlign: 'super' }}>*</Typography>
      </Box>
      <Typography variant='body4' color='text.secondary' sx={{ mt: '2px' }}>*Valid on UPI and Niyo cards</Typography>
    </Box>
  )
  return (
    <Box sx={{ mb: '24px' }}>
      {sortedFlightBasedBreakup?.map((flights: any, index: number) => {
        const taxesAndFees = (flights?.totalTaxAndFee ?? 0);
        return (
          <>
            {sortedFlightBasedBreakup?.length > 1 && (
              <Box bgcolor="#F4F6F5" p="10px 16px" mb="15px">
                <OAFlightRoute
                  from={index === 0 ? departure : arrival}
                  to={index === 0 ? arrival : departure}
                  icon={index === 0 ? 'flight-take-off.svg' : 'flight-land.svg'}
                />
              </Box>
            )}
            <List sx={{ py: '0px', px: '16px' }}>
              <FareListItem
                primary={`Base fare (${totalTravellers} ${
                  totalTravellers > 1 ? 'travellers' : 'traveller'
                })`}
                secondary={`₹${formatNumberInINR(flights?.baseAmount)}`}
              />
              <FareListItem primary="Taxes and fees" secondary={formatToINR(taxesAndFees)} />
              {/* <FareListItem
              primary="Coupon code (NIYOMAXPRO)"
              secondary="- ₹2,000"
              isDiscount
            /> */}
            </List>
          </>
        );
      })}

      {sortedFlightBasedBreakup?.length === 0 && (
        <>
          <List sx={{ py: '0px', px: '16px' }}>
            <FareListItem
              primary={`Base fare (${totalTravellers} ${
                totalTravellers > 1 ? 'travellers' : 'traveller'
              })`}
              secondary={`₹${formatNumberInINR(data?.orderAmount?.baseAmount)}`}
            />
            <FareListItem primary="Taxes and fees" secondary={formatToINR(taxAndFees)} />
          </List>
        </>
      )}
      <Container sx={{ px: '16px' }}>
        {(totalSeats! > 0 || totalMeals! > 0 || totalBaggage! > 0) && (
          <Divider sx={{ mb: '12px' }} />
        )}
        {totalSeats! > 0 && (
          <FareListItem primary="Seat charges" secondary={formatToINR(totalSeatCharges)} />
        )}
        {totalMeals! > 0 && (
          <FareListItem primary="Meal charges" secondary={formatToINR(totalMealCharges)} />
        )}
        {totalBaggage! > 0 && (
          <FareListItem primary="Baggage charges" secondary={formatToINR(totalBaggageCharges)} />
        )}
      </Container>
      {data?.tripType === 'ROUND_TRIP' && <Container sx={{ px: '16px' }}>
        <Divider sx={{ mb: '12px' }} />
        <FareListItem
          fw='500'
          primary={'Total airfare'}
          secondary={`₹${params?.orderId ? formatNumberInINR(data?.orderAmount?.supplierAmount)  : formatNumberInINR(data?.orderAmount?.supplierAmount - (data?.orderAmount?.seatCharges || 0) - (data?.orderAmount?.mealCharges || 0) - (data?.orderAmount?.baggageCharges || 0) + (totalSelectedSmbPrice || 0))}`}
        />
      </Container>}
      <Container sx={{ px: '16px' }}>
        <Divider sx={{ mb: '12px' }} />
        <FareListItem
          primary={convenienceFeeTitle}
          secondary={formatToINR(data?.orderAmount?.convenienceFee ?? 0)}
          secondary1={
            data?.orderAmount?.strikeOfConvenienceFee
              ? formatToINR(data?.orderAmount?.strikeOfConvenienceFee)
              : null
          }
        />
        {burnCoinRs > 0 && (
          <FareListItem
            primary="Niyo Global coins discount"
            secondary={`- ${formatToINR(burnCoinRs)}`}
            isDiscount
          />
        )}
        <FareListItem
          primary="Total amount payable"
          secondary={
            burnCoinsFeat && isCoinsApplied
              ? formatToINR(amountpayable ?? discountPrice)
              : formatToINR(amountpayable ?? totalAmount)
          }
          secondary1={burnCoinRs > 0 ? formatToINR(totalAmount) : null}
          isTotal
        />
        {burnCoinRs > 0 && (
          <OACoinBanner
            title="Money saved on this trip"
            subTitle={burnCoinRs}
            isCurrency={true}
            color="primary"
            sx={{ mb: '10px' }}
          />
        )}
        {coinsToBeEarned > 0 && (
          <OACoinBanner
            title={
              data?.bookingStatus === 'BOOKING_CONFIRMED' &&
              data?.orderCurrentStatus === 'COMPLETED'
                ? 'Niyo coins earned on this trip'
                : 'Niyo coins to be earned on this trip'
            }
            subTitle={data?.orderAmount?.paymentSummary?.earnedCoin ?? getEarnCoin(totalAmount)}
            isCurrency={false}
          />
        )}
      </Container>
    </Box>
  );
};
