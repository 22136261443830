import { FC, useEffect, useState } from 'react';
import { Box, Card, Container, Skeleton, Typography } from '@mui/material';
import { ISegment } from '../../../type';
import PnrDisplay from '../../../components/OAFlightSummary/PnrDisplay';
import OAFlightRoute from '../../../components/OAFlightRoute';
import { OAFlightSummary } from '../../../components';

interface TripDetailsSegmentsProps {
  order: any;
  isLoading: boolean;
}

const TripDetailsSegments: FC<TripDetailsSegmentsProps> = ({ order, isLoading }) => {
  const [nearbyAirportNudges, setNearbyAirportNudges] = useState<{
    onward: { index: number; message: string }[];
    return: { index: number; message: string }[];
  }>({ onward: [], return: [] });

  const onWardFlights = order?.flights?.find((flight: any) => flight?.departureType === 'ONWARD');
  const returnFlights = order?.flights?.find((flight: any) => flight?.departureType === 'RETURN');

  const onwardPnrNumber = onWardFlights?.pnrNumber;
  const returnPnrNumber = returnFlights?.pnrNumber;

  useEffect(() => {
    if (order) {
      const nudges: any = { onward: [], return: [] };
      const searchedFrom = order?.flightSearchInfo?.from?.iata;
      const searchedTo = order?.flightSearchInfo?.to?.iata;

      order?.flights?.forEach((flight: any) => {
        const segments = flight?.segments;
        const firstSegment = segments[0];
        const lastSegment = segments[segments.length - 1];

        if (flight?.departureType === 'ONWARD') {
          if (firstSegment?.departure?.airport?.iata !== searchedFrom) {
            nudges?.onward?.push({
              index: 0,
              message: `The take-off airport is different from the one you searched for.`
            });
          }
          if (lastSegment?.arrival?.airport?.iata !== searchedTo) {
            nudges?.onward?.push({
              index: segments.length - 1,
              message: `The landing airport is different from the one you searched for.`
            });
          }
        } else if (flight.departureType === 'RETURN') {
          if (firstSegment?.departure?.airport?.iata !== searchedTo) {
            nudges?.return?.push({
              index: 0,
              message: `The take-off airport is different from the one you searched for.`
            });
          }
          if (lastSegment?.arrival?.airport?.iata !== searchedFrom) {
            nudges?.return?.push({
              index: segments.length - 1,
              message: `The landing airport is different from the one you searched for.`
            });
          }
        }
      });

      setNearbyAirportNudges(nudges);
    }
  }, [order]);

  const onwardFromAndTo = onWardFlights?.segments?.length > 0 ? {
    from: onWardFlights?.segments?.[0]?.departure?.airport?.city,
    to: onWardFlights?.segments?.[onWardFlights?.segments?.length - 1]?.arrival?.airport?.city
  } : null;

  const returnFromAndTo = returnFlights?.segments?.length > 0 ? {
    from: returnFlights?.segments?.[0]?.departure?.airport?.city,
    to: returnFlights?.segments?.[returnFlights?.segments?.length - 1]?.arrival?.airport?.city
  } : null;

  return (
    <Box sx={{ bgcolor: '#F4F6F5', py: '15px' }}>
      <Container sx={{ mb: '10px' }}>
        <Box
          display="flex"
          justifyContent="space-between"
          color="gray"
          bgcolor="#F4F6F5"
          alignItems="center"
          mb="10px"
        >
          <OAFlightRoute
            from={onwardFromAndTo?.from}
            to={onwardFromAndTo?.to}
            icon="flight-take-off.svg"
          />
          <Box>
            {order?.flights?.[0]?.minirule ? (
              <Typography
                variant="body3"
                p="5px 10px"
                fontWeight={600}
                borderRadius="4px"
                bgcolor={order?.flights?.[0]?.minirule?.refundable ? "#DBF5ED" : "#DEE2E1"}
                color={order?.flights?.[0]?.minirule?.refundable ? "#194244" : "#5A6068"}
                sx={{ borderRadius: '46px' }}
              >
                {order?.flights?.[0]?.minirule?.refundable ? 'Refundable' : 'Non-refundable'}
              </Typography>
            ) : (
              <Skeleton width="100px" />
            )}
          </Box>
        </Box>
        {isLoading ? (
          <Skeleton variant="rectangular" sx={{ height: '82px', width: '100%' }} />
        ) : onwardPnrNumber ? (
          <PnrDisplay pnrDetails={onwardPnrNumber} />
        ) : order?.bookingStatus === 'BOOKING_IN_PROGRESS' ? (
          <Card>
            <Typography
              component="p"
              variant="body3"
              sx={{ color: 'text.secondary', py: '12px', textAlign: 'center' }}
            >
              Booking confirmed! PNR details are on the way
            </Typography>
          </Card>
        ) : null}
      </Container>
      {onWardFlights?.segments?.map((segment: ISegment, index: number) => (
        <OAFlightSummary
          key={segment.segmentId}
          segmentIndex={index}
          selectedFlight={order}
          layovers={onWardFlights?.layovers}
          tripType={order?.tripType === 'ROUND_TRIP' ? 'R': 'O'}
          data={segment}
          loading={isLoading}
          nearbyAirportNudge={nearbyAirportNudges?.onward?.find(nudge => nudge?.index === index)?.message}
        />
      ))}
      {returnFlights?.segments?.length > 0 && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="gray"
            bgcolor="#F4F6F5"
            p="10px 20px"
            mt="15px"
          >
            <OAFlightRoute
              from={returnFromAndTo?.from}
              to={returnFromAndTo?.to}
              icon="flight-land.svg"
            />
            <Box>
              {order?.flights?.[1]?.minirule ? (
                <Typography
                  variant="body3"
                  p="5px 10px"
                  fontWeight={600}
                  borderRadius="4px"
                  bgcolor={order?.flights?.[1]?.minirule?.refundable ? "#DBF5ED" : "#DEE2E1"}
                  color={order?.flights?.[1]?.minirule?.refundable ? "#194244" : "#5A6068"}
                  sx={{ borderRadius: '46px' }}
                >
                  {order?.flights?.[1]?.minirule?.refundable ? 'Refundable' : 'Non-refundable'}
                </Typography>
              ) : (
                <Skeleton width="100px" />
              )}
            </Box>
          </Box>
          <Container sx={{ mb: '10px' }}>
            {isLoading ? (
              <Skeleton variant="rectangular" sx={{ height: '82px', width: '100%' }} />
            ) : returnPnrNumber ? (
              <PnrDisplay pnrDetails={returnPnrNumber} />
            ) : order?.bookingStatus === 'BOOKING_IN_PROGRESS' ? (
              <Card>
                <Typography
                  component="p"
                  variant="body3"
                  sx={{ color: 'text.secondary', py: '12px', textAlign: 'center' }}
                >
                  Booking confirmed! PNR details are on the way
                </Typography>
              </Card>
            ) : null}
          </Container>
        </>
      )}
      {returnFlights?.segments?.map((segment: ISegment, index: number) => (
        <OAFlightSummary
          key={segment.segmentId}
          segmentIndex={index}
          selectedFlight={order}
          layovers={returnFlights?.layovers}
          tripType={order?.tripType === 'ROUND_TRIP' ? 'R': 'O'}
          data={segment}
          loading={isLoading}
          nearbyAirportNudge={nearbyAirportNudges?.return?.find(nudge => nudge?.index === index)?.message}
        />
      ))}
    </Box>
  );
};

export default TripDetailsSegments;