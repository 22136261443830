import React, { useRef, useEffect } from 'react';
import {
  Box,
  Container,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { OACheckbox, OATextField } from '../../components';

export const validateEmail = (email: string) => {
  // Basic email regex pattern
  const pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  return pattern.test(email?.toLowerCase());
};

export const validateMobile = (mobile: string) => {
  // Remove any +91 or 0 prefix
  const sanitizedMobile = mobile.replace(/^\+91/, '').replace(/^0/, '');
  // Basic mobile regex pattern
  const pattern = /^[0-9]{10}$/; // 10-digit mobile number
  return pattern.test(sanitizedMobile);
};

export const ContactDetails = ({ errors, setErrors, contactDetails, setContactDetails }: any) => {
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);

  const setCursorToEnd = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      const length = ref.current.value.length;
      ref.current.setSelectionRange(length, length);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    let error = '';
    let formattedValue = value;

    if (name === 'email') {
      formattedValue = value?.toLowerCase();
      error = validateEmail(formattedValue) ? '' : 'Invalid email address';
    } else if (name === 'phoneNumber') {
      formattedValue = formattedValue
        ?.replace(/^\+91[-\s]?/, '')
        ?.replace(/^0/, '')
        ?.replace(/[\s-]/g, '');
      if (/[^0-9]/.test(formattedValue)) {
        return;
      }
      if (formattedValue?.length > 10) {
        return;
      }
      error = validateMobile(formattedValue) ? '' : 'Invalid mobile number';
    }

    setErrors({
      ...errors,
      [name]: error,
    });

    setContactDetails((prev: any) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };

  const handleFocus = (e: any) => {
    const element = e.target;
    const length = element.value.length;

    // Set cursor to the end of the input field
    requestAnimationFrame(() => {
      element.setSelectionRange(length, length);
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    });
  };

  useEffect(() => {
    if (emailRef?.current) {
      emailRef?.current?.addEventListener('focus', () => setCursorToEnd(emailRef));
    }
    if (phoneNumberRef?.current) {
      phoneNumberRef?.current?.addEventListener('focus', () => setCursorToEnd(phoneNumberRef));
    }

    return () => {
      if (emailRef?.current) {
        emailRef?.current?.removeEventListener('focus', () => setCursorToEnd(emailRef));
      }
      if (phoneNumberRef?.current) {
        phoneNumberRef?.current?.removeEventListener('focus', () => setCursorToEnd(phoneNumberRef));
      }
    };
  }, []);

  return (
    <Container sx={{ my: '16px' }}>
      <Typography variant="body1" fontWeight={600} mb="4px">
        Contact details
      </Typography>
      <Typography variant="body3" sx={{ color: 'grey.800' }}>
        Your e-ticket & PNR will be sent to this email address and phone number.
      </Typography>
      <Box>
        <OATextField
          name="email"
          fullWidth
          label="Email address"
          sx={{ my: '24px' }}
          value={contactDetails?.email}
          onChange={(e) => handleChange(e)}
          error={!!errors.email}
          autoComplete="off"
          inputRef={emailRef}
          inputProps={{
            onFocus: handleFocus,
            onBlur: (e) => e.target.blur(),
          }}
        />
        <FormControl sx={{ width: '100%', mb: '9px' }} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            name="phoneNumber"
            type="tel"
            autoComplete="off"
            value={contactDetails?.phoneNumber}
            onChange={(e) => handleChange(e)}
            error={!!errors?.phoneNumber}
            startAdornment={
              <InputAdornment position="end">
                <InputAdornment position="start">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    +91
                    <Divider orientation="vertical" sx={{ height: '24px', mx: 1 }} />
                  </Box>
                </InputAdornment>
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputRef={phoneNumberRef}
            inputProps={{
              'aria-label': 'weight',
              onFocus: handleFocus,
              onBlur: (e) => e.target.blur(),
            }}
            sx={{
              borderRadius: '8px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #0A1017', // Change border color on focus
              },
            }}
          />
        </FormControl>
        {/* <OACheckbox label="Get booking details & updates Whatsapp" checked={true} /> */}
        {/* <Box>
          <OACheckbox 
            label="Get booking details & updates on Whatsapp" 
            labelStyle={{ fontSize: '12px', lineHeight: '20px' }} 
            checked={contactDetails?.whatsAppNotifiable} 
            onChange={() => setContactDetails({...contactDetails, whatsAppNotifiable: !contactDetails?.whatsAppNotifiable})}
          />
        </Box> */}
      </Box>
    </Container>
  );
};
