import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Box, Card, CardContent, Typography } from '@mui/material';
import FlightCard from './FlightCard';
import { IFlight } from '../../type';
import { VariableSizeList } from 'react-window';
import { OAImage, OASwipeableDrawer } from '../../components';
import { useSelector } from '../../store';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { getUserInfo } from '../../store/slices/userInfoSlice';
import { getAuthInfo } from '../../store/slices/authSlice';
import { getUserInputState } from '../../store/slices/userInputSlice';
import FlightLabel from './FlightLabel';
import { eventsTracker } from '../../utils/ctEventsTracking';
import posthog from 'posthog-js';

interface FlightsListProps {
  flights: IFlight[];
  onFlightSelect: (flight: IFlight) => void;
  tripType: 'R' | 'O';
  isInternational: boolean;
  selectedFlight: IFlight;
  fastestFlight: IFlight;
  lowestPriceFlight: IFlight;
  isFastestAndLowestSame: boolean;
  nearbyAirports: any;
}

const FlightsList: React.FC<FlightsListProps> = ({
  flights,
  onFlightSelect,
  tripType,
  isInternational,
  fastestFlight,
  lowestPriceFlight,
  isFastestAndLowestSame,
  nearbyAirports,
}) => {
  const listRef = useRef<VariableSizeList>(null);
  const { isCoinsApplied, travelDetail } = useSelector(getUserInputState);
  const { coins } = useSelector(getUserInfo);
  const { burnCoinsFeat, noConvenienceFeeBanner, esimOffer } = useSelector(getFeatureFlag);
  const { campaigns } = useSelector(getAuthInfo);
  const [selectedGroup, setSelectedGroup] = useState<IFlight[]>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());

  const handleSimilarFlightClick = (group: IFlight[], tripType: 'R' | 'O') => {
    setSelectedGroup(group);
    setOpenDrawer(true);
  };

  const { similarFlights, unsimilarFlights } = useMemo(() => {
    const groupedFlights: { [key: string]: IFlight[] } = {};

    flights?.forEach(flight => {
      if (flight?.segments && flight?.segments?.length > 0) {
        const airlineCode = flight?.segments[0]?.airline?.code;
        const key = `${airlineCode}-${flight?.price?.value}`;
        if (!groupedFlights[key]) {
          groupedFlights[key] = [];
        }
        groupedFlights[key].push(flight);
      }
    });

    const similar = Object.values(groupedFlights)?.filter(group => group?.length > 1);
    const similarFlightIds = new Set(similar?.flat()?.map(flight => flight?.id));

    const unsimilar = flights?.filter(flight => !similarFlightIds?.has(flight?.id));

    return { similarFlights: similar, unsimilarFlights: unsimilar };
  }, [flights]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [isCoinsApplied, campaigns, coins, burnCoinsFeat]);

  const isInternationalAndRoundTrip = isInternational && tripType === 'R';
  const burningCoins = coins > 9 && isCoinsApplied && burnCoinsFeat;

  const isDomesticOrInternationalOneWay = tripType === 'O' && (isInternational || !isInternational);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleCloseBottomDrawer = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker({
      flowName: 'Flight',
      screenName: 'SRP',
      ctaAction: 'SRP_MoreOptionsClose',
      screenDuration: totalScreenDuration?.toString(),
      UserAction: {
        userAction: 'User has closes the bottom sheet (tap out/close icon)'
      }
    }, posthog)
    setOpenDrawer(false)
  }
  const [cardHeights, setCardHeights] = useState<{ [key: number]: number }>({});

  const getItemSize = useCallback((index: number) => {
    if (index === 2 && noConvenienceFeeBanner) {
      return (isInternational && tripType === 'R') ? 124 : 104; // Height for the convenience fee banner
    }
    if (index === 6 && isInternational && esimOffer) {
      return (isInternational && tripType === 'R') ? 124 : 104; // Height for the eSIM banner
    }

    // Use the dynamically calculated height if available
    if (cardHeights[index]) {
      return cardHeights[index];
    }

    // Fallback to estimated height if not yet calculated
    const baseHeight = 175; // Base height for a flight card
    const campaignHeight = campaigns?.eligibleCampaigns?.length > 0 ? 30 : 0;
    const coinHeight = (isCoinsApplied && coins > 0 && burnCoinsFeat) ? 30 : 0;

    let additionalHeight = 0;

    const flightIndex = noConvenienceFeeBanner && index > 2 ? index - 1 : index;
    const flight = flights[flightIndex];
    if (flight && similarFlights.some(group => group.includes(flight))) {
      additionalHeight += 30; // Height for the "same price options" button
    }

    return baseHeight + campaignHeight + coinHeight + additionalHeight;
  }, [noConvenienceFeeBanner, isInternational, tripType, esimOffer, campaigns, isCoinsApplied, coins, burnCoinsFeat, flights, similarFlights, cardHeights]);

  const renderFlightLabels = (flight: IFlight, international: boolean) => (
    <>
      {(flight?.id === fastestFlight?.id) && (flight?.id === lowestPriceFlight?.id) ? (
        <>
          <FlightLabel
            bgColor='#215039'
            icon='percent.svg'
            text='Lowest Price'
            customStyles={{ right: international ? '90px' : '16px', zIndex: 10 }}
          />
          <FlightLabel
            bgColor='#F0935F'
            icon='fastest.svg'
            text='Fastest'
            customStyles={{ right: international ? '120px' : '112px', zIndex: 10 }}
          />
        </>
      ) : (
        <>
          {lowestPriceFlight && flight?.id === lowestPriceFlight?.id && (
            <FlightLabel
              bgColor='#215039'
              icon='percent.svg'
              text='Lowest Price'
              customStyles={{ right: international ? '90px' : '16px', zIndex: 10 }}
            />
          )}
          {fastestFlight && flight?.id === fastestFlight?.id && isInternational && (
            <FlightLabel
              bgColor='#F0935F'
              icon='fastest.svg'
              text='Fastest'
              customStyles={{ right: international ? '90px' : '16px', zIndex: 10 }}
            />
          )} 
        </>
      )}
    </>
  );

  const Row = ({ index, style }: any) => {
    if (index === 2 && noConvenienceFeeBanner) {
      return (
        <div style={style}>
          <Box sx={{ marginBottom: '15px' }} py='10px'>
            <OAImage src='no-convenience-fee.svg' folder='icons' alt='no-convenience-fee' width='100%' />
          </Box>
        </div>
      );
    }

    if (index === 6 && esimOffer) {
      return (
        <div style={style}>
          <Box sx={{ marginBottom: '15px' }} py='10px'>
            <OAImage src='esim-srp.svg' folder='offer' alt='esim-banner' width='100%' />
          </Box>
        </div>
      );
    }

    const flightIndex = noConvenienceFeeBanner && index > 2 ? index - 1 : index;
    const totalFlights = similarFlights.length + unsimilarFlights.length;
    
    let flight: IFlight | null = null;
    let isSimilar = false;

    if (flightIndex < totalFlights) {
      if (flightIndex < similarFlights.length) {
        const similarGroup = similarFlights[flightIndex];
        if (similarGroup && similarGroup.length > 0) {
          flight = similarGroup[0];
          isSimilar = true;
        }
      } else {
        const unsimilarIndex = flightIndex - similarFlights.length;
        flight = unsimilarFlights[unsimilarIndex];
      }
    }

    if (!flight) return null;
      
    return (
      <div style={style}>
        <Box 
          key={flight?.id} 
          sx={{ marginBottom: '15px' }} 
          pt="10px" 
          px='16px' 
          ref={(el: HTMLDivElement | null) => {
            if (el) {
              const height = el.getBoundingClientRect().height;
              if (height !== cardHeights[index]) {
                setCardHeights(prev => ({ ...prev, [index]: height }));
                if (listRef.current) {
                  listRef.current.resetAfterIndex(index);
                }
              }
            }
          }}
        >
          <FlightCard
            data={flight}
            onClick={() => onFlightSelect(flight!)}
            tripType={tripType}
            isInternational={isInternational}
            nearbyAirports={nearbyAirports}
            sx={{ borderBottomRightRadius: isSimilar ? "0px" : undefined }}
          />
          {isSimilar && similarFlights[flightIndex].length > 1 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'white',
                  padding: '4px 8px',
                  paddingRight: '3px',
                  borderRadius: '4px',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  zIndex: 1000,
                  bgcolor: '#009E82',
                  borderBottomLeftRadius: '8px',
                  borderBottomRightRadius: '8px',
                  borderTopRightRadius: '0px',
                  borderTopLeftRadius: '0px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
                  eventsTracker({
                    flowName: 'Flight',
                    screenName: 'SRP',
                    ctaAction: 'SRP_MoreOptions',
                    screenDuration: totalScreenDuration.toString(),
                    UserAction: {
                      userAction: 'User has tapped on the green button to open the bottomsheet'
                    }
                  }, posthog)
                  handleSimilarFlightClick(similarFlights[flightIndex], tripType)
                }}
              >
                <Typography variant='body4' color='white'>+{similarFlights[flightIndex].length - 1} same price options</Typography>
                <OAImage src='right-chevron.svg' folder='icons' alt='right' width='16px' height='16px' />
              </Box>
            </Box>
          )}
        </Box>
      </div>
    );
  };
  return (
    <Grid container>
      <Grid item xs={12} sx={{ height: '100%', overflowY: 'auto' }} mb="35px" pt="10px">
        {isInternational && tripType === 'R' ? (
          <VariableSizeList
            ref={listRef}
            height={1500}
            width="100%"
            itemSize={getItemSize}
            itemCount={flights?.length + (noConvenienceFeeBanner ? 1 : 0)} // Add 1 for the banner if applicable
          >
            {Row}
          </VariableSizeList>
        ) : !isInternational && tripType === 'O' ?  (
          <>
            {flights?.slice(0, 2)?.map((flight: IFlight, index: number) => (
            <Box
              key={flight.id}
              mx='16px'
              mt='8px'
              mb="20px"
              position='relative'
            >
              {renderFlightLabels(flight, false)}
              <FlightCard
                data={flight}
                onClick={() => onFlightSelect(flight)}
                tripType={tripType}
                isInternational={isInternational}
                nearbyAirports={nearbyAirports}
              />
            </Box>
          ))}
          {noConvenienceFeeBanner && (
            <Box sx={{ marginBottom: '15px' }}>
              <OAImage src='no-convenience-fee.svg' folder='icons' alt='no-convenience-fee' width='100%' />
            </Box>
          )}
          {flights?.slice(2)?.map((flight: IFlight, index: number) => (
            <Box
              key={flight.id}
              mx='16px'
              mb="20px"
              position='relative'
            >
              {renderFlightLabels(flight, false)}
              <FlightCard
                data={flight}
                onClick={() => onFlightSelect(flight)}
                tripType={tripType}
                isInternational={isInternational}
                nearbyAirports={nearbyAirports}
              />
            </Box>
          ))}
          </>
        ) : (
          <>
            {similarFlights?.slice(0, 2)?.map((similarCollection: any, index: number) => (
              <Box
                mx='16px'
                mt='8px'
                mb="20px"
              >
                <Card
                  sx={{ zIndex: 3, p: 0, border: 'none', borderBottomRightRadius: '0px' }}
                  onClick={() => onFlightSelect(similarCollection[0])}>
                  <CardContent sx={{ p: '0 !important' }}>
                    <FlightCard
                      data={similarCollection[0]}
                      onClick={() => { }}
                      tripType={tripType}
                      isInternational={isInternational}
                      sx={{ borderBottomRightRadius: "0px" }}
                      nearbyAirports={nearbyAirports}
                    />
                  </CardContent>
                </Card>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  onClick={() => {
                    const totalScreenDuration = Math?.floor((Date?.now() - startTime) / 1000);
                    eventsTracker({
                      flowName: 'Flight',
                      screenName: 'SRP',
                      ctaAction: 'SRP_MoreOptions',
                      screenDuration: totalScreenDuration?.toString(),
                      UserAction: {
                        userAction: 'User has tapped on the green button to open the bottomsheet'
                      }
                    }, posthog)
                    handleSimilarFlightClick(similarCollection, 'O')
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: 'primary.main',
                      color: 'white',
                      padding: '4px 8px',
                      paddingRight: '3px',
                      borderRadius: '4px',
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                      zIndex: 1000,
                      bgcolor: '#009E82',
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopRightRadius: '0px',
                      borderTopLeftRadius: '0px',
                      display: 'flex',
                    }}
                    onClick={() => {
                      const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
                      eventsTracker({
                        flowName: 'Flight',
                        screenName: 'SRP',
                        ctaAction: 'SRP_MoreOptions',
                        screenDuration: totalScreenDuration?.toString(),
                        UserAction: {
                          userAction: 'User has tapped on the green button to open the bottomsheet'
                        }
                      }, posthog)
                      handleSimilarFlightClick(similarCollection, 'O')
                    }}
                  >
                    <Typography variant='body4' color='white'>+{similarCollection?.length - 1} same price options</Typography>
                    <OAImage src='right-chevron.svg' height="16px" width="16px" folder='icons' alt='right' />
                  </Box>
                </Box>
              </Box>
            ))}
            {noConvenienceFeeBanner && (
              <Box sx={{ marginBottom: '15px' }}>
                <OAImage src='no-convenience-fee.svg' folder='icons' alt='no-convenience-fee' width='100%' />
              </Box>
            )}
            {similarFlights?.slice(noConvenienceFeeBanner ? 2 : 0, esimOffer ? 5 : similarFlights?.length)?.map((similarCollection: any, index: number) => (
              <Box
                mx='16px'
                mb="20px"
              >
                <Card
                  sx={{ zIndex: 3, p: 0, border: 'none', borderBottomRightRadius: '0px' }}
                  onClick={() => onFlightSelect(similarCollection[0])}>
                  <CardContent sx={{ p: '0 !important' }}>
                    <Typography variant="h6">
                      <FlightCard
                        data={similarCollection[0]}
                        onClick={() => { }}
                        tripType={tripType}
                        isInternational={isInternational}
                        sx={{ borderBottomRightRadius: "0px" }}
                        nearbyAirports={nearbyAirports}

                      />
                    </Typography>
                  </CardContent>
                </Card>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: 'primary.main',
                      color: 'white',
                      padding: '4px 8px',
                      paddingRight: '3px',
                      borderRadius: '4px',
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                      zIndex: 1000,
                      bgcolor: '#009E82',
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopRightRadius: '0px',
                      borderTopLeftRadius: '0px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
                      eventsTracker({
                        flowName: 'Flight',
                        screenName: 'SRP',
                        ctaAction: 'SRP_MoreOptions',
                        screenDuration: totalScreenDuration?.toString(),
                        UserAction: {
                          userAction: 'User has tapped on the green button to open the bottomsheet'
                        }
                      }, posthog)
                      handleSimilarFlightClick(similarCollection, 'O')
                    }}
                  >
                    <Typography variant='body4' color='white'>+{similarCollection?.length - 1} same price options</Typography>
                    <OAImage src='right-chevron.svg' folder='icons' alt='right' width='16px' height='16px' />
                  </Box>
                </Box>
                {(similarCollection[0]?.id === fastestFlight?.id) && (similarCollection[0]?.id === lowestPriceFlight?.id) ? (
                  <>
                    <FlightLabel
                      bgColor='#215039'
                      icon='percent.svg'
                      text='Lowest Price'
                      customStyles={{ right: '90px', zIndex: 10 }}
                    />
                    <FlightLabel
                      bgColor='#F0935F'
                      icon='fastest.svg'
                      text='Fastest'
                      customStyles={{ right: '16px', zIndex: 10 }}
                    />
                  </>
                ) : (
                  <>
                    {lowestPriceFlight && similarCollection[0]?.id === lowestPriceFlight?.id && (
                      <FlightLabel
                        bgColor='#215039'
                        icon='percent.svg'
                        text='Lowest Price'
                        customStyles={{ zIndex: 10 }}
                      />
                    )}
                    {fastestFlight && similarCollection[0]?.id === fastestFlight?.id && isInternational && (
                      <FlightLabel
                        bgColor='#F0935F'
                        icon='fastest.svg'
                        text='Fastest'
                        customStyles={{ zIndex: 10 }}
                      />
                    )}
                  </>
                )}
              </Box>
            ))}
            {esimOffer && isInternational &&
              <Box mb='15px'>
                <OAImage src='esim-srp.svg' folder='offer' alt='esim-banner' width='100%' />
              </Box>
            }
            {similarFlights?.slice(esimOffer ? 5 : 2)?.map((similarCollection: any, index: number) => (
              <Box
                mx='16px'
                mb="20px"
              >
                <Card
                  sx={{ zIndex: 3, p: 0, border: 'none', borderBottomRightRadius: '0px' }}
                  onClick={() => onFlightSelect(similarCollection[0])}>
                  <CardContent sx={{ p: '0 !important' }}>
                    <Typography variant="h6">
                      <FlightCard
                        data={similarCollection[0]}
                        onClick={() => { }}
                        tripType={tripType}
                        isInternational={isInternational}
                        sx={{ borderBottomRightRadius: "0px" }}
                        nearbyAirports={nearbyAirports}
                      />
                    </Typography>
                  </CardContent>
                </Card>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: 'primary.main',
                      color: 'white',
                      padding: '4px 8px',
                      paddingRight: '3px',
                      borderRadius: '4px',
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                      zIndex: 1000,
                      bgcolor: '#009E82',
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopRightRadius: '0px',
                      borderTopLeftRadius: '0px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
                      eventsTracker({
                        flowName: 'Flight',
                        screenName: 'SRP',
                        ctaAction: 'SRP_MoreOptions',
                        screenDuration: totalScreenDuration?.toString(),
                        UserAction: {
                          userAction: 'User has tapped on the green button to open the bottomsheet'
                        }
                      }, posthog)
                      handleSimilarFlightClick(similarCollection, 'O')
                    }}
                  >
                    <Typography variant='body4' color='white'>+{similarCollection?.length - 1} same price options</Typography>
                    <OAImage src='right-chevron.svg' folder='icons' alt='right' width='16px' height='16px' />
                  </Box>
                </Box>
                {(similarCollection[0]?.id === fastestFlight?.id) && (similarCollection[0]?.id === lowestPriceFlight?.id) ? (
                  <>
                    <FlightLabel
                      bgColor='#215039'
                      icon='percent.svg'
                      text='Lowest Price'
                      customStyles={{ right: '90px', zIndex: 10 }}
                    />
                    <FlightLabel
                      bgColor='#F0935F'
                      icon='fastest.svg'
                      text='Fastest'
                      customStyles={{ right: '16px', zIndex: 10 }}
                    />
                  </>
                ) : (
                  <>
                    {lowestPriceFlight && similarCollection[0]?.id === lowestPriceFlight?.id && (
                      <FlightLabel
                        bgColor='#215039'
                        icon='percent.svg'
                        text='Lowest Price'
                        customStyles={{ zIndex: 10 }}
                      />
                    )}
                    {fastestFlight && similarCollection[0]?.id === fastestFlight?.id && isInternational && (
                      <FlightLabel
                        bgColor='#F0935F'
                        icon='fastest.svg'
                        text='Fastest'
                        customStyles={{ zIndex: 10 }}
                      />
                    )}
                  </>
                )}
              </Box>
            ))}
            {unsimilarFlights.map((flight) => (
              <Box key={flight?.id} sx={{ marginBottom: '15px' }} px='16px'>
                <FlightCard
                  data={flight}
                  onClick={() => onFlightSelect(flight)}
                  tripType={tripType}
                  isInternational={isInternational}
                  nearbyAirports={nearbyAirports}
                />
                {(flight?.id === fastestFlight?.id) && (flight?.id === lowestPriceFlight?.id) ? (
                  <>
                    <FlightLabel
                      bgColor='#215039'
                      icon='percent.svg'
                      text='Lowest Price'
                      customStyles={{ right: '90px', zIndex: 10 }}
                    />
                    <FlightLabel
                      bgColor='#F0935F'
                      icon='fastest.svg'
                      text='Fastest'
                      customStyles={{ right: '16px', zIndex: 10 }}
                    />
                  </>
                ) : (
                  <>
                    {lowestPriceFlight && flight?.id === lowestPriceFlight?.id && (
                      <FlightLabel
                        bgColor='#215039'
                        icon='percent.svg'
                        text='Lowest Price'
                        customStyles={{ zIndex: 10 }}
                      />
                    )}
                    {fastestFlight && flight?.id === fastestFlight?.id && isInternational && (
                      <FlightLabel
                        bgColor='#F0935F'
                        icon='fastest.svg'
                        text='Fastest'
                        customStyles={{ zIndex: 10 }}
                      />
                    )}
                  </>
                )}
              </Box>
            ))}
          </>
        )}
      </Grid>
      <OASwipeableDrawer
        open={openDrawer}
        onClose={handleCloseBottomDrawer}
        onOpen={() => setOpenDrawer(true)}
        title="Same price options"
        isContainer={false}
        maxHeight={90}
      >
        <Box bgcolor='#F4F6F5'>
          {selectedGroup?.map((flight) => (
            <Box key={flight?.id} sx={{ marginBottom: '15px' }} px='16px' mt='8px'>
              {(flight?.id === fastestFlight?.id) && (flight?.id === lowestPriceFlight?.id) ? (
                <>
                  <FlightLabel
                    bgColor='#215039'
                    icon='percent.svg'
                    text='Lowest Price'
                    customStyles={{ top: '90px', zIndex: 99, right: '90px' }}
                  />
                  <FlightLabel
                    bgColor='#F0935F'
                    icon='fastest.svg'
                    text='Fastest'
                    customStyles={{ top: '90px', zIndex: 99, right: '32px' }}
                  />
                </>
              ) : (
                <>
                  {lowestPriceFlight && flight?.id === lowestPriceFlight?.id && (
                    <FlightLabel
                      bgColor='#215039'
                      icon='percent.svg'
                      text='Lowest Price'
                      customStyles={{ top: '90px', zIndex: 99, right: '32px' }}
                    />
                  )}
                  {fastestFlight && flight?.id === fastestFlight?.id && isInternational && (
                    <FlightLabel
                      bgColor='#F0935F'
                      icon='fastest.svg'
                      text='Fastest'
                      customStyles={{ top: '90px', zIndex: 99, right: '32px' }}
                    />
                  )}
                </>
              )}
              <FlightCard
                data={flight}
                onClick={() => {
                  const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
                  eventsTracker({
                    flowName: 'Flight',
                    screenName: 'SRP',
                    ctaAction: 'SRP_MoreOptionsSelect',
                    screenDuration: totalScreenDuration?.toString(),
                    UserAction: {
                      userAction: 'User has selected a flight from the bottom sheet'
                    }
                  }, posthog)
                  onFlightSelect(flight)
                }}
                tripType={tripType}
                isInternational={isInternational}
                nearbyAirports={nearbyAirports}
              />

            </Box>
          ))}
        </Box>
      </OASwipeableDrawer>
    </Grid>
  );
};

export default FlightsList;